import { ShippingCardStyle } from "./ShippingCard.style";

interface ShippingCardProps {
  heading: string;
  list: string[];
}

const ShippingCard = (props: ShippingCardProps) => {
  const { heading, list } = props;

  return (
    <ShippingCardStyle>
      <span className="card-dot" />

      <h3>{heading}</h3>

      <ul>
        {list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </ShippingCardStyle>
  );
};

export default ShippingCard;
