import { ReactNode } from 'react';
import { Section } from "../common";
import { SectionType } from "../common/Section/Section";
import { SectionWithVisualPropsStyle } from "./SectionWithVisualProps.style";

export enum SectionWithVisualPropsType {
  left = "left",
  right = "right",
}

interface SectionWithVisualProps {
  heading: string;
  description: ReactNode;
  image: string;
  sectionType?: SectionType;
  type?: SectionWithVisualPropsType;
  className?: string;
}

const SectionWithVisualProps = (props: SectionWithVisualProps) => {
  const {
    heading,
    description,
    image,
    sectionType,
    className="",
    type = SectionWithVisualPropsType.left,
  } = props;

  return (
    <Section type={sectionType} className={className}>
      <SectionWithVisualPropsStyle type={type}>
        <article className="content">
          <h3>{heading}</h3>
          <p>{description}</p>
        </article>

        <article className="visual">
          <img src={image} alt={heading} />
        </article>
      </SectionWithVisualPropsStyle>
    </Section>
  );
};

export default SectionWithVisualProps;
