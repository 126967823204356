import { TestimonialCardStyle } from "./TestimonialCarousel.style";

interface TestimonialCardProps {
  name: string;
  title: string;
  quote: string;
}

const TestimonialCard = (props: TestimonialCardProps) => {
  const { name, title, quote } = props;

  return (
    <TestimonialCardStyle>
      <div className="quote-icon">
        <img src="/images/icons/quotes.png" alt="quote" />
      </div>

      <p>{quote}</p>

      <p className="divider" />

      <h4>{name}</h4>
      <h6>{title}</h6>
    </TestimonialCardStyle>
  )
}

export default TestimonialCard;