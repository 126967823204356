import styled from "styled-components";
import { ContainerStyle } from "../../style/Common.style";

export const OurVisionStyle = styled.article`
  background: #19222B;

  h3 {
    font-size: 34px;
  }
  h3, p {
    color: #fff;
  }

  .our-vision {
    background: #19222B;
  }
  
  .our-vision-wrapper {
    ${ContainerStyle};

    max-width: 720px;
  }
`;