import { styled } from "styled-components";
import { ContainerStyle } from "../../style/Common.style";

interface HeaderStyleProps {
  image: string;
}

export const HeaderStyle = styled.header<HeaderStyleProps>`
  background: url(${({image}) => image}), lightgray 50% / cover no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 15px;

  @media ( max-width: 768px){
    padding: 15px;
  }

  .header-wrapper {
    ${ContainerStyle};

    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 1;
    
    @media(max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .header-left {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 40px 0;
    align-items: flex-start;

    @media ( max-width: 768px ) {
      gap: 20px;
    }
  }

  .header-right {
    display: flex;
    justify-content: flex-end;
    
    @media(max-width: 768px) {
      justify-content: center;
    }
  }

  .heading {
    color: #FF8500;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;

    @media ( max-width: 768px){
      font-size: 28px;
      line-height: 1.5;
    }

    p {
      color: #FFF;
      font-size: 50px;
      font-weight: 300;

      @media ( max-width: 768px){
        font-size: 28px;
      }
    }
  }

  .subheading {
    color: #FFF;
  }
`;

export const InnerHeaderStyle = styled.header<HeaderStyleProps>`
  background: url(${({image}) => image}), lightgray 50% / cover no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 768px) {
    height: 140px;
  }

  .heading {
    color: #FFF;
    font-size: 50px;
    font-weight: 700;

    @media(max-width: 768px) {
      font-size: 34px;
      padding: 0 20px;
      text-align: center;
    }
  }
`;