import { BookingIcon, QouteIcon, SecureDeliveryIcon, ShippingIcon, TowingServiceIcon, TrackVehicleIcon } from "../../assets";
import { Card } from "../common";
import { Section } from "../common/Section";
import { ServicesStyle } from "./Services.style";

const Services = () => {
  return (
    <Section title="Services">
      <ServicesStyle>
        <Card
          icon={<ShippingIcon />}
          title="Door to Door Shipping"
          description="Seamless door-to-door car shipping, where we convey your automobile domestic with a private contact."
        />

        <Card
          icon={<BookingIcon />}
          title="Instant Booking"
          description="Get an instant quote and discover the personalized care your vehicle deserves on its  journey with us.
"
        />

        <Card
          icon={<QouteIcon />}
          title="Instant Quote"
          description="Join us in bridging the country, uniting dreams through our heartfelt cross-country auto deliveries."
        />

        <Card
          icon={<SecureDeliveryIcon />}
          title="Safe and Secured Delivery"
          description="Your vehicle's safety is our priority. We make sure it reaches you just like you'd expect – safe and sound."
        />

        <Card
          icon={<TrackVehicleIcon />}
          title="Track Your Vehicle"
          description="Keep an eye on your vehicle, giving you the peace of mind."
        />

        <Card
          icon={<TowingServiceIcon />}
          title="Premium Towing Service"
          description="Think about our premium towing carrier as a pleasant helper to your automobile, giving it special care while it needs a hand."
        />
      </ServicesStyle>
    </Section>
  );
};

export default Services;
