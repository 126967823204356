import { useFormContext } from "react-hook-form";
import { InputWrapperStyle } from "./InputField.style";

interface InputWrapperProps {
  label: string;
  children: React.ReactNode;
  name?: string;
  className?: string;
}

const InputWrapper = (props: InputWrapperProps) => {
  const { label, children, name = '', className } = props;

  const {
    formState: { errors },
  } = useFormContext();

  const errorMessage =
  typeof errors[name] === "string"
    ? errors[name]
    : errors[name]?.message || "";
    
  return (
    <InputWrapperStyle className={className && className}>
      <p className="field-label">{label}</p>
      {children}

      {errorMessage && <p className="error-message">{errorMessage.toString()}</p>}
    </InputWrapperStyle>
  );
};

export default InputWrapper;
