import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import InputWrapper from "./InputWrapper";
import { InputFieldStyle } from "./InputField.style";

interface InputFieldProps extends Omit<ControllerProps, 'render'>{
  label: string;
  name: string;
  inputProps?: any;
  className?: string;
}

const InputField = (props: InputFieldProps) => {
  const { label, name, inputProps, className, ...rest } = props;

  const { control } = useFormContext();

  return (
    <InputWrapper label={label} name={name} className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputFieldStyle className="field-input" type="text" {...field} {...inputProps} />
        )}
        {...rest}
      />
    </InputWrapper>
  );
};

export default InputField;
