import { Controller, ControllerProps, useFormContext } from "react-hook-form";

import InputWrapper from "../InputField/InputWrapper";
import { InputDateStyle } from "./InputDate.style";

interface InputDateProps extends Omit<ControllerProps, "render"> {
  label: string;
  name: string;
}

const InputDate = (props: InputDateProps) => {
  const { label, name, ...rest } = props;

  const { control } = useFormContext();

  return (
    <InputWrapper label={label} name={name}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <InputDateStyle type="date" {...field} />}
        {...rest}
      />
    </InputWrapper>
  );
};

export default InputDate;
