import { Carousel } from "antd";
import styled from "styled-components";

export const TestimonialCarouselStyle = styled(Carousel)`
  text-align: center;
  max-width: 660px;
  margin: auto;

  .slick-dots {
    bottom: -20px;
    li {
      button {
        height: 8px;
        background-color: #A0A0A0;
      }

      &.slick-active {
        button {
          background-color: #FF8500;
        }
      }
    }
  }
`;

export const TestimonialCardStyle = styled.article`
  background: #fff;
  padding: 40px 60px;
  border-radius: 7px;

  .quote-icon {
    img {
      width: 100px;
      margin: auto;
    }
  }

  .divider {
    max-width: 350px;
    height: 1px;
    margin: 20px auto;
    background: linear-gradient(90deg, #FFF 0%, #696A6D 50.52%, #FFF 100%);
  }
`;