import { styled } from "styled-components";
import { ButtonType } from "./Button";

type ButtonStyleProps = {
  type?: ButtonType;
};

export const ButtonStyle = styled.button<ButtonStyleProps>`
  background: ${({ type }) => type === ButtonType.filled ? "#FF8500" : "transparent"};
  color: ${({ type }) => type === ButtonType.filled ? "#fff" : "#FF8500"};
  border: 1px solid #FF8500;
  padding: 13px 32px;
  outline: none;
  text-align: center;
  box-shadow: unset;
  cursor: pointer;
  transition: all 200ms linear;
  font-size: 18px;
  font-weight: 700;

  &:hover {
    background: ${({ type }) => type === ButtonType.filled ? "#c76b08" : "#FF8500"};
    color: #fff;
  }

  &:disabled {
    opacity: 0.5;
  }
`;
