import { ExportGoalsSectionStyle, TileStyle } from "./ExportGoalsSection.style";

export enum TileType {
  primary = "primary",
  secondary = "secondary",
}

interface TileProps {
  value: string;
  label: string;
  type?: TileType;
}

const Tile = (props: TileProps) => {
  const { value, label, type = TileType.primary } = props;

  return (
    <TileStyle type={type}>
      <h1 className="tile-value">{value}</h1>
      <p className="tile-label">{label}</p>
    </TileStyle>
  );
};

const ExportGoalsSection = () => {
  return (
    <ExportGoalsSectionStyle>
      <article className="section-wrapper">
        <h1 className="section-heading">
          We Expert Our Goals
          <p>& We Strive to Provide the Best</p>
        </h1>

        <article className="tiles-wrapper">
          <Tile value="100+" label="Cities Covered" />
          <Tile value="98%" label="Customer Recommendation" type={TileType.secondary} />
        </article>
      </article>
    </ExportGoalsSectionStyle>
  );
};

export default ExportGoalsSection;
