import { styled } from "styled-components";
import { ContainerStyle } from "../../style/Common.style";
import { TileType } from "./ExportGoalsSection";

export const ExportGoalsSectionStyle = styled.section`
  background: #19222B;

  .section-wrapper {
    ${ContainerStyle};

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    align-items: center;
    padding: 50px 15px;

    @media ( max-width: 768px ) {
      grid-template-columns: 1fr;
    }

    .section-heading {
      font-size: 34px;
      font-weight: 700;
      color: #FF8500;

      p {
        font-weight: 300;
        font-size: 34px;
        color: #FFF;
      }
    }
  }

  .tiles-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
`;

interface TileStyleProps {
  type: TileType;
};

export const TileStyle = styled.div<TileStyleProps>`
  background: #fff;
  border-radius: 10px;
  width: 225px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ type }) => type === TileType.primary ? "#FF8500" : "#CB1E0A"};

  .tile-value {
    font-size: 34px;
    font-weight: 700;
  }

  .tile-label {
    font-size: 16px;
    font-weight: 400;
    color: inherit;
  }
`;