import styled from "styled-components";

const BannerWrapper = styled.div`
  background-color: #CB1E0A; /* A bright color for the banner */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  font-size: 2rem;
  font-weight: bold;
  position: sticky;
  top: 80px;
  z-index: 1000; /* Ensures the banner stays above other content */
`;

const Banner = () => {
  return <BannerWrapper>FLAT 30% OFF</BannerWrapper>;
};

export default Banner;
