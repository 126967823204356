import { FormProvider, useForm } from "react-hook-form";
import { Button, InputField } from "../common";
import { ButtonType } from "../common/Button/Button";
import { ContactUsFormStyle } from "./ContactUsForm.style";
import axios from "axios";
import { TextAreaField } from "../common/TextAreaField";
import { message } from "antd";

const ContactUsForm = () => {
  const methods = useForm({
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      message: "",
    },
  });

  const handleFormSubmit = (values: any) => {
    // axios.post(
    //   `https://isaahtrucking.com/php/mail.php?subject=Contact&formType=contact&name=${values.name}&phone=${values.phone}&email=${values.email}&message=${values.message}`
    // ).then((res) => {
    //   message.success("Message sent successfully");
    // });
  };

  return (
    <FormProvider {...methods}>
      <ContactUsFormStyle onSubmit={methods.handleSubmit(handleFormSubmit)}>
        <InputField
          label="Name"
          name="name"
          rules={{ required: "This field is required" }}
        />

        <InputField
          label="Phone"
          name="phone"
          inputProps={{ type: "number" }}
          rules={{ required: "This field is required" }}
        />

        <div className="single-column">
          <InputField
            label="Email"
            name="email"
            inputProps={{ type: "email" }}
            rules={{ required: "This field is required" }}
          />
        </div>

        <div className="single-column">
          <TextAreaField
            label="Message"
            name="message"
            rules={{ required: "This field is required" }}
          />
        </div>

        <Button
          className="submit-button"
          onClick={() => {}}
          type={ButtonType.filled}
          label="Send Message"
        />
      </ContactUsFormStyle>
    </FormProvider>
  );
};

export default ContactUsForm;
