import styled from "styled-components";
import { SectionWithVisualType } from "./SectionWithVisual";

interface SectionWithVisualStyleProps {
  type: SectionWithVisualType;
}

export const SectionWithVisualStyle = styled.section<SectionWithVisualStyleProps>`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  flex-direction: ${({type}) => type === SectionWithVisualType.left ? "row" : "row-reverse"};

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  img {
    width: 100%;
  }

  .content {
    max-width: 475px;
    width: 100%;

    h3 {
      margin-bottom: 20px;
    }
  }
`;