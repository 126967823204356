import {
  ExportGoalsSection,
  Footer,
  Header,
  Services,
  ShippingSection,
  TestimonialCarousel,
  WhyChooseUs,
} from "../../components";
import { HomeStyle } from "./Home.style";

const Home = () => {
  return (
    <HomeStyle>
      <Header image="/images/banners/home.png" />

      <Services />
      <ExportGoalsSection />
      <ShippingSection />
      <WhyChooseUs />
      <TestimonialCarousel />

      <Footer />
    </HomeStyle>
  );
};

export default Home;
