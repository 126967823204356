import { Checkbox } from "antd";
import styled from "styled-components";

export const CheckboxFieldStyle = styled(Checkbox)`
  align-items: flex-start;
  display: flex;

  .ant-checkbox {
    align-self: flex-start;
    margin-top: 5px;

    &.ant-checkbox-checked {
      &:hover {
        .ant-checkbox-inner {
          border-color: #ff8500 !important;
          background-color: #ff8500 !important;
        }
      }

      .ant-checkbox-inner {
        background-color: #ff8500 !important;
        border-color: #ff8500 !important;
      }
    }
  }
`;
