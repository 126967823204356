import { CardStyle } from "./Card.style";

interface CardProps {
  title?: string;
  description?: string;
  icon?: React.ReactNode;
  isCentered?: boolean;
};

const Card = (props: CardProps) => {
  const { title, description, icon, isCentered } = props;

  return (
    <CardStyle isCentered={isCentered}>
      {icon && icon}
      {title && <h3>{title}</h3>}
      {description && <p>{description}</p>}
    </CardStyle>
  )
}

export default Card;
