import { Steps } from "antd";
import { styled } from "styled-components";

export const QuotationFormStyle = styled.form`
  border-radius: 10px;
  background: #FFF;
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  
  .form-heading {
    font-size: 28px;
    font-weight: 700;
    color: #19222B;
    padding: 10px 29px 0;
    
    p {
      color: #FF8500;
    }
  }
  
  .fields-wrapper {
    padding: 11px 29px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
  }

  .steps-button {
    width: 100%;
    border-radius: 0 0 10px 10px;
    background: #CB1E0A;
    border-color: #CB1E0A;
  }
`;

export const StepsStyle = styled(Steps)`
  margin-top: 10px;

  &.ant-steps {
    &.ant-steps-vertical {
      flex-direction: row;
    }

    .ant-steps-item-tail {
      position: absolute !important;
      top: 12px !important;
      inset-inline-start: 0 !important;
      width: 100% !important;
      margin-inline-start: 56px !important;
      padding: 4px 24px !important;
      padding-left: 0 !important;

      &:after {
        display: inline-block !important;
      width: 100% !important;
      height: 1px !important;
      background: rgba(5, 5, 5, 0.08) !important;
      border-radius: 1px !important;
      transition: background 0.3s !important;
      content: "" !important;
      }
    }
  }

  
  .ant-steps-item-icon {
    margin-inline-start: 25px !important;
  }

  .ant-steps-icon {
    display: none;
  }

  .ant-steps-item-finish {
    .ant-steps-icon {
      display: inline;
    }
    .ant-steps-item-icon {
      background-color: #fff;
      border-color: #CB1E0A;

      svg {
        fill: #CB1E0A;
      }
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: #CB1E0A;
      border-color: #CB1E0A;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: #E2E6EC;
    }
  }

  .ant-steps-item-tail {
    &:after {
      background-color: #E2E6EC !important;
    }
  }
`;

export const FinalStepStyle = styled.article`
  text-align: center;
  padding: 15px;
  margin-top: 40px;
`;