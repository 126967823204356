import { styled } from "styled-components";

export const ShippingCardStyle  = styled.article`
  border-top: 1px solid #FF8500;
  padding: 40px 20px;
  position: relative;

  @media (max-width: 768px) {
    border-top: none;
    border-left: 1px solid #FF8500;
    padding: 20px 40px;
    margin-left: 20px;
  }

  .card-dot {
    background-color: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    border: 1px solid #FF8500;
    position: absolute;
    top: -19px;
    left: 20px;

    @media (max-width: 768px) {
      border-top: none;
      border-left: 1px solid #FF8500;
      left: -19px;
      top: 20px;
    }

    &:before {
      content: "";
      width: 29px;
      height: 29px;
      background-color: #FF8500;
      border-radius: 100%;
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  ul {
    li {
      color: #696A6D;
      list-style-type: disc;
      margin-left: 20px;
    }
  }
`;
