import { Footer, Header } from '../../components';
import { SectionWithVisualProps } from '../../components/SectionWithVisualProps';
import { HeaderType } from '../../components/Header/Header';
import { SectionWithVisualPropsType } from '../../components/SectionWithVisualProps/SectionWithVisualProps';
import { SectionType } from '../../components/common/Section/Section';
import { CarShippingStyles } from './CarShipping.style';

const CarShipping = () => {
  return (
    <CarShippingStyles>
      <Header type={HeaderType.inner} heading="Car Shipping Texas" image="/images/banners/about-us.png" />

      <SectionWithVisualProps
        heading="Why Choose Our Car Shipping Service in Texas?"
        description={
          <div>
            <p>
              Shipping your car to or from Texas can be a daunting task, but our door-to-door car transportation service
              ensures a seamless, stress-free experience. Whether you're relocating, buying a car online, or simply need
              to ship your vehicle to another state, we've got you covered.
            </p>
          </div>
        }
        image="/images/services/door-to-door-shipping.png"
        type={SectionWithVisualPropsType.left}
        sectionType={SectionType.light}
      />

      <SectionWithVisualProps
        heading="Our Texas Car Shipping Services"
        description={
          <div>
            <h4>Door-to-Door Car Transportation in Texas</h4>
            <p>
              Our service guarantees that your vehicle is picked up from your desired location and delivered directly to
              your destination without needing terminals. This eliminates the hassle of additional travel, saving you
              time and effort.
            </p>

            <h4>Shipping Car to Texas Made Easy</h4>
            <p>
              Whether you’re moving to Houston, Dallas, Austin, or a remote Texas town, we specialize in delivering cars
              efficiently and safely. Our service is tailored to meet the unique needs of every customer.
            </p>

            <h4>Reliable Auto Shipping Texas Options</h4>
            <p>
              We understand that every vehicle is different. Our solutions include open transport, enclosed transport,
              and expedited shipping, ensuring we cater to all types of vehicles and timelines.
            </p>
          </div>
        }
        image="/images/services/door-to-door-shipping.png"
        type={SectionWithVisualPropsType.right}
        sectionType={SectionType.light}
      />

      <SectionWithVisualProps
        heading="Why We're the Best Car Shipping Company in Texas"
        description={
          <div>
            <h4>Affordable Pricing</h4>
            <p>
              We offer competitive rates without compromising on quality. Get an instant quote to find out how
              affordable our car shipping services in Texas can be.
            </p>

            <h4>Experienced Drivers and Reliable Carriers</h4>
            <p>
              Our professional drivers and well-maintained carriers ensure the safety of your vehicle throughout the
              journey.
            </p>

            <h4>Advanced Tracking System</h4>
            <p>Stay informed with real-time updates on your vehicle’s location, ensuring peace of mind.</p>

            <h4>Insured Vehicle Shipping</h4>
            <p>
              Your car’s safety is our top priority. We provide comprehensive insurance coverage for all shipments to
              safeguard against unforeseen incidents.
            </p>
          </div>
        }
        image="/images/services/door-to-door-shipping.png"
        type={SectionWithVisualPropsType.left}
        sectionType={SectionType.light}
      />

      <SectionWithVisualProps
        heading="How Does Texas Vehicle Shipping Work?"
        description={
          <div>
            <ol>
              <li>
                <strong>Get a Quote:</strong> Start by filling out our simple online form with details about your car,
                origin, and destination.
              </li>
              <li>
                <strong>Schedule Your Pickup:</strong> Once you’ve accepted our quote, schedule a convenient pickup time
                and location.
              </li>
              <li>
                <strong>Vehicle Inspection:</strong> Before loading, our team will perform a thorough inspection and
                document your vehicle’s condition.
              </li>
              <li>
                <strong>Secure Transport:</strong> Your car will be safely loaded onto a carrier for its journey to or
                from Texas.
              </li>
              <li>
                <strong>On-Time Delivery:</strong> We’ll deliver your car directly to your specified address, completing
                a final inspection upon arrival.
              </li>
            </ol>
          </div>
        }
        image="/images/services/door-to-door-shipping.png"
        type={SectionWithVisualPropsType.right}
        sectionType={SectionType.light}
      />

      <SectionWithVisualProps
        heading="Factors That Affect Car Shipping Costs in Texas"
        description={
          <div>
            <h4>Distance and Route</h4>
            <p>
              The longer the distance, the higher the cost. Urban areas are often more affordable due to easier
              accessibility.
            </p>

            <h4>Type of Transport</h4>
            <p>
              <strong>Open Transport:</strong> Cost-effective and ideal for standard vehicles.
              <br />
              <strong>Enclosed Transport:</strong> Offers extra protection for luxury or classic cars but comes at a
              premium.
            </p>

            <h4>Vehicle Size and Weight</h4>
            <p>Larger or heavier vehicles may incur additional fees.</p>

            <h4>Seasonal Demand</h4>
            <p>Peak seasons, such as summer and holidays, often result in higher prices due to increased demand.</p>
          </div>
        }
        image="/images/services/door-to-door-shipping.png"
        type={SectionWithVisualPropsType.left}
        sectionType={SectionType.light}
      />

      <SectionWithVisualProps
        heading="Tips for Preparing Your Vehicle for Shipping in Texas"
        description={
          <div>
            <ol>
              <li>
                <strong>Clean Your Vehicle:</strong> Ensure the car is clean to easily identify any pre-existing damage.
              </li>
              <li>
                <strong>Remove Personal Items:</strong> Take out all personal belongings for safety and compliance with
                regulations.
              </li>
              <li>
                <strong>Check for Leaks and Mechanical Issues:</strong> Ensure your car is in good working condition to
                avoid complications during transport.
              </li>
              <li>
                <strong>Document Vehicle Condition:</strong> Take pictures and notes of your car’s current state for
                your records.
              </li>
            </ol>
          </div>
        }
        image="/images/services/door-to-door-shipping.png"
        type={SectionWithVisualPropsType.right}
        sectionType={SectionType.light}
      />
      <SectionWithVisualProps
        heading="Why Texas Residents Trust Us for Car Shipping"
        description={
          <div>
            <ul>
              <li>
                <strong>Exceptional Customer Service:</strong> We prioritize clear communication and customer
                satisfaction.
              </li>
              <li>
                <strong>Flexibility:</strong> From standard vehicles to exotic cars, we handle all types of shipping
                needs.
              </li>
              <li>
                <strong>Proven Track Record:</strong> Hundreds of Texas residents have relied on us for efficient, safe,
                and affordable car transportation.
              </li>
            </ul>
          </div>
        }
        image="/images/services/door-to-door-shipping.png"
        type={SectionWithVisualPropsType.left}
        sectionType={SectionType.light}
      />

      <SectionWithVisualProps
        heading="Frequently Asked Questions"
        description={
          <div>
            <ol>
              <li>
                <strong>How much does it cost to ship a car to Texas?</strong>
                <p>
                  Car shipping costs depend on factors like distance, vehicle type, and transport method. Get a free
                  quote for an accurate estimate.
                </p>
              </li>
              <li>
                <strong>How long does vehicle shipping to Texas take?</strong>
                <p>Transit times vary based on distance and route but typically range from 1-10 days.</p>
              </li>
              <li>
                <strong>Can I ship an inoperable vehicle to Texas?</strong>
                <p>Yes, we offer services for non-operational vehicles using specialized equipment.</p>
              </li>
              <li>
                <strong>Is my car insured during transport?</strong>
                <p>Absolutely! All vehicles are covered by comprehensive insurance for the duration of the shipment.</p>
              </li>
              <li>
                <strong>What’s the best time to ship a car to Texas?</strong>
                <p>For the best rates, consider shipping during off-peak seasons like fall or winter.</p>
              </li>
            </ol>
            <p>
              Ready to experience seamless car shipping in Texas? Get your free quote today and let us handle the rest!
            </p>
          </div>
        }
        image="/images/services/door-to-door-shipping.png"
        type={SectionWithVisualPropsType.right}
        sectionType={SectionType.light}
      />

      <Footer />
    </CarShippingStyles>
  );
};

export default CarShipping;
