import styled from "styled-components";

export const ContactUsFormStyle = styled.form`
  display: grid;
  flex-direction: 1fr 1fr;
  gap: 5px;

  .single-column, .submit-button {
    grid-column: 1/3;
  }
`;