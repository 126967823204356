import { Input } from "antd";
import { styled } from "styled-components";

export const InputFieldStyle = styled(Input)``;

export const InputWrapperStyle = styled.fieldset`
  border: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 15px;

  &.full-width {
    grid-column: 1/-1;
  }

  .field-label {
    line-height: 30px;
    color: #696a6d;
    font-weight: 500;
  }

  .error-message {
    color: #cb1e0a;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
`;
