import { AboutCompany, Footer, Header, OurCoreValues, OurMission, OurVision } from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { AboutStyle } from "./About.style";

const About = () => {
  return (
    <AboutStyle>
      <Header
        type={HeaderType.inner}
        heading="About Us"
        image="/images/banners/about-us.png"
      />

      <AboutCompany />

      <OurVision />

      <OurMission />

      <OurCoreValues />
      
      <Footer />
    </AboutStyle>
  );
};

export default About;
