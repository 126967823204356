import { useState } from "react";
import {
  Button,
  Footer,
  Header,
  ShipperAgreementContent,
} from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { AgreementStyle } from "./Agreement.style";
import AgreementForm from "./AgreementForm";
import FinalStep from "../../components/QuotationForm/Steps/FinalStep";
import { Section } from "../../components/common";

const Agreement = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <AgreementStyle>
      <Header
        heading="Shipper Agreement"
        image="/images/banners/terms-and-conditions.png"
        type={HeaderType.inner}
        className="header"
      />
      {isSubmitted && (
        <div className="final-step">
          <FinalStep />
          <Button label="Go Back" onClick={() => setIsSubmitted(false)} />
        </div>
      )}

      {!isSubmitted && (
        <Section className="agreement-container">
          <div className="agreement-wrapper">
            <ShipperAgreementContent />
          </div>

          <div className="form-wrapper">
            <AgreementForm setIsSubmitted={setIsSubmitted} />
          </div>
        </Section>
      )}

      <Footer />
    </AgreementStyle>
  );
};

export default Agreement;
