import { FinalStepStyle } from "../QuotationForm.style";

const FinalStep = () => {
  return (
    <FinalStepStyle>
      <div className="step-wrapper">
        <img
          src={`/images/icons/success-icon.png`}
          width={"50px"}
          height={"50px"}
          alt="Check Icon"
        />
        <h2>Thank You</h2>
        <p>
          Your request has been submitted successfully. We will contact you
          shortly.
        </p>
      </div>
    </FinalStepStyle>
  );
};

export default FinalStep;
