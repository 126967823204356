import React, { useEffect, useState } from "react";

import { NavbarStyle } from "./Navbar.style";
import { navItems } from "../../mockData/navItems";
import { Link, useLocation } from "react-router-dom";

interface NavbarProps {
  logo: string;
}

const Navbar = ({ logo }: NavbarProps) => {
  const [isNavSticky, setStickyNav] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector("nav");
      const navTop = nav?.offsetTop || 0;
      setStickyNav(window.scrollY > navTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  const toggle = () => {
    if (window.innerWidth < 768) {
      setShowDropdown(!showDropdown);
    }
  };

  return (
    <NavbarStyle
      isNavSticky={isNavSticky}
      className={showDropdown ? "active" : ""}
    >
      <div className="navbar-wrapper">
        <Link className="branding-logo" to="/">
          <img className="site-logo" height={60} width={60} src={logo} alt="site-logo" />
        </Link>

        <ul className="nav-links desktop">
          {navItems.map((data: any, index: number) => {
            const { name, link } = data;

            return (
              <li className="link-wrapper" key={`nav-item-${index}`}>
                <Link
                  to={link}
                  className={location.pathname === link ? "active" : ""}
                >
                  {name}
                </Link>
              </li>
            );
          })}
        </ul>

        <p className="contact">
          <a href="tel:+16107856302" target="__blank">
            +1(346)-459-6422
          </a>

          {/* <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/send?phone=17724099734"
          >
            <WhatsappIcon />
          </a> */}
        </p>

        <button className="menu-toggle-button " onClick={toggle}>
          <span className="toggle-menu-bar" />
          <span className="toggle-menu-bar" />
          <span className="toggle-menu-bar" />
        </button>
      </div>

      <ul
        className={
          showDropdown ? "nav-links mobile active" : "nav-links mobile"
        }
      >
        {navItems.map((data: any, index: number) => {
          const { name, link } = data;

          return (
            <li className="link-wrapper" key={`nav-item-${index}`}>
              <Link
                className={location.pathname === link ? "active" : ""}
                onClick={toggle}
                to={link}
              >
                {name}
              </Link>
            </li>
          );
        })}
      </ul>
    </NavbarStyle>
  );
};

export default Navbar;
