import { ContactIcon, EmailIcon, LocationIcon } from "../../assets";
import { ContactUsForm, Footer, Header } from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { ContactItemStyle, ContactUsStyle } from "./ContactUs.style";

const ContactItem = ({
  icon,
  content,
}: {
  icon: React.ReactNode;
  content: React.ReactNode;
}) => {
  return (
    <ContactItemStyle>
      {icon}
      <p className="item-content">{content}</p>
    </ContactItemStyle>
  );
};

const ContactUs = () => {
  return (
    <>
      <ContactUsStyle>
        <Header
          heading="Contact Us"
          image="/images/banners/contact-us.png"
          type={HeaderType.inner}
        />

        <article className="contact-us-wrapper">
          <section>
            <ContactUsForm />
          </section>

          {/* <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.5471698673667!2d-95.50816508488302!3d30.03605092462216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640d74ddcb52cf1%3A0xe75a3b7b5e241bfb!2sOakwood%20Chase%20Dr%2C%20Spring%2C%20TX%2077379!5e0!3m2!1sen!2sus!4v1713793227978!5m2!1sen!2sus"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen={false}
          loading="lazy"
          title="Oakwood Chase Dr, Spring, TX 77379 Map"
          /> */}
        </article>

        <section className="contacts">
          <ContactItem
            icon={<ContactIcon />}
            content={<a href="tel:+1(346)-459-6422">+1(346)-459-6422</a>}
          />

          <ContactItem
            icon={<EmailIcon />}
            content={
              <a href="contact@addiautotransport.com">contact@addiautotransport.com</a>
            }
          />

          <ContactItem
            icon={<LocationIcon />}
            content="Spring Texas 77379"
          />
        </section>
      </ContactUsStyle>
      <Footer />
    </>
  );
};

export default ContactUs;
