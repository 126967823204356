import { Link } from "react-router-dom";
import { CheckboxField, InputField } from "../../common";

const Step3 = () => {
  return (
    <article className="fields-wrapper">
      <InputField
        name="name"
        label="Name"
        rules={{ required: "This field is required" }}
      />
      <InputField
        name="email"
        inputProps={{ type: "email" }}
        label="Email"
        rules={{ required: "This field is required" }}
      />
      <InputField
        name="phone"
        inputProps={{ type: "number" }}
        label="Phone Number"
        rules={{ required: "This field is required" }}
      />
      <CheckboxField
        name="terms"
        inputProps={{ required: true }}
        label={
          <>
            Agreed to the{" "}
            <Link target="_blank" to="/terms-conditions">
              Terms & Conditions
            </Link>{" "}
            and{" "}
            <Link target="_blank" to="/shipper-agreement">
              Shipper Agreement
            </Link>
            .
          </>
        }
      />
    </article>
  );
};

export default Step3;
