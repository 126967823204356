import { Footer, Header, ShipperAgreementContent } from "../../components";
import { HeaderType } from "../../components/Header/Header";
import { PrivacyPolicyStyle } from "../PrivacyPolicy/PrivacyPolicy.style";

const ShipperAgreement = () => {
  return (
    <PrivacyPolicyStyle>
      <Header
        heading="Shipper Agreement"
        image="/images/banners/terms-and-conditions.png"
        type={HeaderType.inner}
      />

      <ShipperAgreementContent />

      <Footer />
    </PrivacyPolicyStyle>
  );
};

export default ShipperAgreement;
