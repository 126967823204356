import styled from "styled-components";
import { ContainerStyle } from "../../style/Common.style";

type NavbarStyleProps = {
  isNavSticky: boolean;
};

export const NavbarStyle = styled.nav<NavbarStyleProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${(props) => (props.isNavSticky ? "#fff" : "transparent")};
  transition: all 200ms linear;
  box-shadow: ${(props) =>
    props.isNavSticky ? "0 1px 10px rgb(0 0 0 / 10%)" : "none"};
  z-index: 100;
  background: #fff;

  &.active {
    height: 100%;
    
    .toggle-menu-bar {
      &:first-child {
        transform: translate(0px, 6px) rotate(45deg);
      }

      &:nth-child(2) {
        display: none;
      }

      &:last-child {
        transform: translateY(-3px) rotate(-45deg);
      }
    }
  }

  .menu-toggle-button {
    background: none;
    border: 0;
    margin: 0;
    display: inline-flex;
    flex-direction: column;
    outline: none;

    .toggle-menu-bar {
      transition: all 300ms linear;
      width: 30px;
      height: 3px;
      border-radius: 1.6px;
      margin-top: 6.4px;
      background: #000;

      &:first-child {
        margin-top: 0;
      }
    }

    @media (min-width: 768px) {
      display: none;
    }
  }

  .navbar-wrapper {
    ${ContainerStyle};

    padding: 7px 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .nav-links {
    display: flex;

    &.desktop {
      @media (max-width: 768px) {
        display: none;
      }
    }

    &.mobile {
      max-height: 0;
      overflow: hidden;
      transition: all 200ms linear;
      display: grid;
      justify-content: center;
      grid-row-gap: 25px;
      background: #fff;
      padding: 0px;

      @media (min-width: 768px) {
        display: none;
      }

      &.active {
        max-height: 100vh;
        padding: 20px;
      }

      .link-wrapper {
        text-align: center;
        
        a {
          color: #696A6D;
          font-weight: 500;
          font-size: 14px;
          transition: all 200ms linear;

          &:hover {
            color: #000;
          }
        }

      }

    }

    .link-wrapper {
      margin: 0 10px;

      a {
        color: #696A6D;
        transition: all 200ms linear;

        &:hover {
          color: #000;
        }
      
        &.active {
          color: #CB1E0A;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100%;
            height: 2px;
            background: #CB1E0A;
          }
          
          @media ( max-width: 768px ) {
            color: #CB1E0A;
          }
        }
      }
    }
  }

  .contact {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;