import styled from "styled-components";
import { ContainerStyle } from "../../style/Common.style";

export const FooterStyle = styled.footer`
  background: #2A3845;
  padding: 35px 0 0;

  a {
     color: #fff;
     font-size: 16px;
     font-weight: 400;
     transition: all 200ms linear;

     &:hover {
        color: #FF8500;
     }
  }

  .footer-wrapper {
    ${ContainerStyle};

    padding: 0 15px;
    display: flex;
    justify-content: space-between;

    @media ( max-width: 768px ) {
      flex-direction: column;
      gap: 30px;
    }

    .footer-links {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      @media ( max-width: 768px ) {
        flex-direction: column;
        gap: 30px;
      }
    }

    .footer-social-links {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }
  }

  .copy-right {
    background: #19222B;
    color: #586A7C;
    margin-top: 35px;
    padding: 10px 15px;
    text-align: center;
  }
`;