import { InputDate, InputSelect, RadioField } from "../../common";

const Step1 = () => {
  return (
    <article className="fields-wrapper">
      <InputSelect
        name="from"
        label="From"
        rules={{ required: "This field is required" }}
      />
      <InputSelect
        name="to"
        label="To"
        rules={{ required: "This field is required" }}
      />
      <InputDate
        name="date"
        label="Date"
        rules={{ required: "This field is required" }}
      />
      <RadioField
        label="Transport Type"
        name="transportType"
        options={[
          {
            label: "Enclosed",
            value: "Enclosed",
          },
          {
            label: "Open",
            value: "Open",
          },
        ]}
        rules={{ required: "This field is required" }}
      />
    </article>
  );
};

export default Step1;
