import { styled } from "styled-components";

export const ServicesStyle = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media ( max-width: 900px ) {
    grid-template-columns: 1fr 1fr;
  }

  @media ( max-width: 767px ) {
    grid-template-columns: 1fr;
  }
`;