import { Section } from "../common";
import { SectionType } from "../common/Section/Section";
import { SectionWithVisualStyle } from "./SectionWithVisual.style";

export enum SectionWithVisualType {
  left = "left",
  right = "right",
}

interface SectionWithVisual {
  heading: string;
  description: string;
  image: string;
  sectionType?: SectionType;
  type?: SectionWithVisualType;
  className?: string;
}

const SectionWithVisual = (props: SectionWithVisual) => {
  const {
    heading,
    description,
    image,
    sectionType,
    className="",
    type = SectionWithVisualType.left,
  } = props;

  return (
    <Section type={sectionType} className={className}>
      <SectionWithVisualStyle type={type}>
        <article className="content">
          <h3>{heading}</h3>
          <p>{description}</p>
        </article>

        <article className="visual">
          <img src={image} alt={heading} />
        </article>
      </SectionWithVisualStyle>
    </Section>
  );
};

export default SectionWithVisual;
