import { Section } from "../common";
import { SectionType } from "../common/Section/Section";
import TestimonialCard from "./TestimonialCard";
import { TestimonialCarouselStyle } from "./TestimonialCarousel.style";

const TestimonialCarousel = () => {
  return (
    <Section title="Testimonials" type={SectionType.dark}>
      <TestimonialCarouselStyle>
        <TestimonialCard
          title="Head of Finance"
          name="Michael Clark"
          quote="The Driver was very detailed and accessible 24/7. Answered all questions accurately, Quickly. Car delivered from Alabama to Alaska 4 days early. Excellent service, with confidence. We would give 7* if they had them. Awesome!"
        />
        <TestimonialCard
          title="General manager"
          name="Michael Clark"
          quote="The Driver was very detailed and accessible 24/7. Answered all questions accurately, Quickly. Car delivered from Alabama to Alaska 4 days early. Excellent service, with confidence. We would give 7* if they had them. Awesome!"
        />
        <TestimonialCard
          title="Chief of Finance"
          name="Michael Clark"
          quote="The Driver was very detailed and accessible 24/7. Answered all questions accurately, Quickly. Car delivered from Alabama to Alaska 4 days early. Excellent service, with confidence. We would give 7* if they had them. Awesome!"
        />
      </TestimonialCarouselStyle>
    </Section>
  );
};

export default TestimonialCarousel;
