import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorPrimary: "#FF8500",
            algorithm: true, // Enable algorithm
          },
          Radio: {
            colorPrimary: "#FF8500",
            algorithm: true, // Enable algorithm
          },
          Select: {
            colorPrimary: "#FF8500",
            algorithm: true, // Enable algorithm
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
